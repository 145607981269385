/** @jsx jsx */
/** @jsxFrag React.Fragment **/
import { jsx } from '@emotion/core';
import { Button } from '@material-ui/core';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

function ImageViewer() {
    const [attachement, setAttachment] = React.useState(null)
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const [type, SetType] = React.useState('img');

    React.useEffect(() => {
        if (queryParams.get("attachement")) setAttachment('https://connect.medtigo.com/'+queryParams.get("attachement"))
        // SetType(attachement.split('.')[1])
        if (queryParams.get("attachement")) {
            if (queryParams.get("attachement").includes('jpg') || queryParams.get("attachement").includes('jpeg') || queryParams.get("attachement").includes('png')) {
                SetType('img');
            } else if (queryParams.get("attachement").includes('pdf')) {
                SetType('pdf');
            } else SetType('download')
        }
    })

    React.useEffect(()=>{
        if(type=='download'){
            window.open(attachement);
        }
    },[type])

    return <div css={{
        background: 'linear-gradient(#04002C 30%, #223363, #41699C)',
        height: '100vh',
        display: 'flex',
        flexDirection: "column",
        // width: 'device-width',
        fontFamily: 'sans-serif',
    }}>
        <div css={{
            backgroundColor: '#2C365E',
            height: '60px',
        }}>
            <img src='static/images/logo.png' css={{
                height: 'inherit',
                width: 'inherit',
                paddingLeft: '20px',
                paddingBottom: '2px',
                paddingTop: '2px'
            }}></img>
        </div>
        <div style={{ width: '70%', height: '100%', width: '100%', alignItems: 'center', display: 'flex', justifyContent: "center" }}>
            <div style={{ width: '70%', height: '80%', overflowY: 'auto', display:'flex', justifyContent:'center' }}>
                {
                    type == 'img' ?
                        <img height={400} src={`${attachement}`} /> :
                        type == 'pdf' ?
                            <object data={`${attachement}`}
                                type="application/pdf"
                                width="1000px" height="500px"></object>
                            : <Button variant='outlined'>
                            <a style={{color:'white'}} href={`${attachement}`}>Download Attachment</a>
                            </Button>
                }
            </div>
        </div>

    </div>
}

export default ImageViewer
