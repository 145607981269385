/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route, Link } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import NewLayout from 'src/layouts/NewLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import NewLoadingScreen from './components/NewLoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import CountryGuard from 'src/components/CountryGuard';
import SchedulingGuard from 'src/components/SchedulingGuard';
import PaymentGuard from './components/PaymentGuard';
import CMEGuard from 'src/components/CMEGuard';
import ExpiryGuard from './components/ExpiryGuard';
import MedtigoOrangeGuard from './components/MedtigoOrangeGuard';
import { useSelector } from 'react-redux';
import FeedbackView from 'src/views/feedback';
import TaskResponseView from 'src/views/certificateTaskResponse';
import ImageViewer from './components/ImageViewer';
import {useHistory } from 'react-router-dom';

const ServicesRedirect = () => {
  const history = useHistory();
  React.useEffect(() => {
    history.replace('/dashboard');
  }, [history]);
  return null;
};

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/login" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: false,
    path: '/regenerate',
    component: lazy(() => import('src/views/regenerate'))
  },
  {
    exact: false,
    path: '/onBoardingResendEmail',
    component: lazy(() => import('src/views/onboardingLicenseEmail'))
  },
  {
    exact: false,
    path: '/serviceForm',
    component: lazy(() => import('src/views/serviceRegistrationForm'))
  },
  {
    exact: false,
    path: '/taskResponse',
    component: lazy(() => import('src/views/certificateTaskResponse'))
  },
  {
    exact: false,
    path: '/feedback',
    component: lazy(() => import('src/views/feedback'))
  },
  {
    exact: false,
    path: '/lead-confirmation/:token',
    component: lazy(() => import('src/views/leadConfirmationPage'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forgot-password',
    component: lazy(() => import('src/views/auth/ForgotPasswordView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/reset-password',
    component: lazy(() => import('src/views/auth/ResetPasswordView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/services',
    component: ServicesRedirect
  },
  {
    path: '/v1',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      // {
      //   exact: true,
      //   path: '/compliance',
      //   component: lazy(() => import('src/views/compliance'))
      // },
      {
        exact: true,
        path: '/account',
        component: () => <Redirect to="/home" />
      },
      {
        exact: true,
        path: '/v1/home',
        expiry: ExpiryGuard,
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: '/v1/market-auto-login',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: '/v1/faqs',
        expiry: ExpiryGuard,
        component: lazy(() => import('src/views/faqs'))
      },
      {
        exact: true,
        path: '/v1/certificates',
        expiry: ExpiryGuard,
        component: lazy(() => import('src/views/certificates'))
      },
      {
        exact: true,
        path: '/v1/imageViewer',
        component: lazy(() => import('src/components/ImageViewer'))
      },
      {
        exact: true,
        path: '/v1/notifications',
        expiry: ExpiryGuard,
        component: lazy(() => import('src/views/notification'))
      },
      {
        exact: true,
        path: '/v1/schedule/my-schedule',
        guard: SchedulingGuard,
        expiry: ExpiryGuard,
        component: lazy(() => import('src/views/Scheduling/Screens/MyScheduleView'))
      },
      {
        exact: true,
        path: '/v1/schedule/shift-request',
        guard: SchedulingGuard,
        expiry: ExpiryGuard,
        component:  lazy(() =>import('src/views/Scheduling/Screens/ShiftRequest'))
      },
      {
        exact: true,
        path: '/v1/schedule/shift-schedule',
        guard: SchedulingGuard,
        expiry: ExpiryGuard,
        component:lazy(() =>import('src/views/Scheduling/Screens/ScheduleView'))
      },
      {
        exact: true,
        path: '/v1/schedule/payment-request',
        guard: PaymentGuard,
        expiry: ExpiryGuard,
        component:  lazy(() =>import('src/views/Scheduling/Screens/PaymentRequest'))
      },
      {
        exact: true,
        path: '/v1/schedule/payment-request-history',
        guard: PaymentGuard,
        expiry: ExpiryGuard,
        component:  lazy(() =>import('src/views/Scheduling/Screens/PaymentRequest/RequestHistory'))
      },
      {
        exact: true,
        path: '/v1/schedule/payment-request-details/:requestId',
        guard: PaymentGuard,
        expiry: ExpiryGuard,
        component:  lazy(() =>import('src/views/Scheduling/Screens/PaymentRequest/RequestDurationTable'))
      },
      {
        exact: true,
        path: '/v1/schedule/availability',
        guard: SchedulingGuard,
        expiry: ExpiryGuard,
        component:lazy(() =>import('src/views/Scheduling/Screens/Availability'))
      },
      {
        exact: true,
        path: '/v1/schedule/dndcalender',
        guard: SchedulingGuard,
        expiry: ExpiryGuard,
        component:lazy(() =>import('src/views/Scheduling/components/Dndcalender'))
      },
      {
        exact: true,
        path: '/v1/schedule/team-calendar',
        guard: SchedulingGuard,
        expiry: ExpiryGuard,
        component:lazy(() =>import('src/views/Scheduling/components/TeamSchedule'))
      },
      {
        exact: true,
        path: '/v1/staffing',
        guard: CountryGuard,
        expiry: ExpiryGuard,
        component: lazy(() => import('src/views/staffing'))
      },
      {
        exact: true,
        path: '/v1/settings',
        expiry: ExpiryGuard,
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/v1/learning',
        expiry: ExpiryGuard,
        component: lazy(() => import('src/views/learning'))
      },
      {
        exact: true,
        path: '/v1/learning/course/:courseID',
        expiry: ExpiryGuard,
        component: lazy(() => import('src/views/learning/SingleCourse'))
      },
      {
        exact: true,
        guard: CMEGuard,
        expiry: ExpiryGuard,
        path: '/v1/learning/cme-survey',
        component: lazy(() => import('src/views/learning/CMESurvey'))
      },
      {
        exact: true,
        guard: CMEGuard,
        expiry: ExpiryGuard,
        path: '/v1/learning/cme-survey-opioid',
        component: lazy(() => import('src/views/learning/OpiodSurvayForm'))
      },
      // {
      //   exact: true,
      //   path: '/publisher-health-test',
      //   component: lazy(() => import('src/views/adds'))
      // },
      {
        exact: true,
        path: '/v1/opioid',
        expiry: ExpiryGuard,
        component:lazy(() =>import('src/views/learning/SingleCourse/_components/LastUnitForm'))
      },
      {
        exact: true,
        path: '/v1/invoice/travel-expense',
        expiry: ExpiryGuard,
        guard: SchedulingGuard,
        component:lazy(() =>import('src/views/management/TravelExpenseCreate'))
      },
      {
        exact: true,
        path: '/v1/invoice/meals-expense',
        expiry: ExpiryGuard,
        guard: SchedulingGuard,
        component:lazy(() =>import('src/views/management/MealsExpenseCreate'))
      },
      {
        exact: true,
        path: '/v1/invoice/site-expense',
        expiry: ExpiryGuard,
        guard: SchedulingGuard,
        component:lazy(() =>import('src/views/management/SiteExpenseCreate'))
      },
      {
        exact: true,
        path: '/thankyou/fullCertificateRequest/:courseName/:email',
        component: lazy(() => import('src/views/learning/ThankyouPage'))
      },
      {
        exact: true,
        path: '/syllabusReader/:syllabusName',
        component: lazy(() => import('src/views/learning/SyllabusReader'))
      },
      {
        exact: true,
        path: '/simulationCaseSatusView/',
        component: lazy(() => import('src/views/management/SimulationCaseStatusView'))
      },
      {
        exact: true,
        path: '/v1/cme-compliance/',
        expiry: ExpiryGuard,
        component: lazy(() => import('src/views/StateSpecificCME'))
      },
      {
        exact: true,
        path: '/simulationRedirection/:Case/:Source',
        component: lazy(() => import('src/views/simulation/simulationRedirection'))
      },
      {
        exact: true,
        path: '/noSimulationCaseAccess/:productId',
        component: lazy(() => import('src/views/simulation/simulationNoAccessView'))
      },
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/home" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/',
    guard: AuthGuard,
    layout: NewLayout,
    routes: [
      {
        exact: true,
        path: '/redirect/:place',
        component: lazy(() => import('src/views/redirections/index'))
      },
      {
        exact: true,
        path: '/home',
        component: () => <Redirect to="/dashboard" />
      },
      {
        exact: true,
        path: '/notifications',
        component: () => <Redirect to="/state-licensing" />
      },
      {
        exact: true,
        path: '/dashboard',
        expiry: ExpiryGuard,
        component: lazy(() => import('src/views/dashboard'))
      },
      {
        exact: true,
        path: '/certificates',
        expiry: ExpiryGuard,
        component: lazy(() => import('src/views/new_certificates'))
      },
      {
        exact: true,
        expiry: ExpiryGuard,
        path: '/old-certificates',
        component: lazy(() => import('src/views/new_certificates'))
      },
      {
        exact: true,
        expiry: ExpiryGuard,
        path: '/cme-compliance',
        component: lazy(() => import('src/views/new_certificates'))
      },
      {
        exact: true,
        expiry: ExpiryGuard,
        path: '/state-compliance',
        component: lazy(() => import('src/views/StateCompliance'))
      },
      {
        exact: true,
        expiry: ExpiryGuard,
        path: '/setting',
        component: lazy(() => import('src/views/newSetting'))
      },
      {
        exact: true,
        expiry: ExpiryGuard,
        path: '/state-licensing',
        component: lazy(() => import('src/views/new_notification'))
      },
      {
        exact: true,
        expiry: ExpiryGuard,
        path: '/state-licensing-support',
        component: lazy(() => import('src/views/new_notification/mobile/support'))
      },
      {
        exact: true,
        expiry: ExpiryGuard,
        path: '/my-learning',
        component: lazy(() => import('src/views/new_certificates/_components/MyLearning'))
      },
      {
        exact: true,
        expiry: ExpiryGuard,
        path: '/learning/course/:courseID',
        component: lazy(() => import('src/views/new_learning/SingleCourse'))
      },
      {
        exact: true,
        guard: CMEGuard,
        expiry: ExpiryGuard,
        path: '/learning/cme-survey',
        component: lazy(() => import('src/views/new_learning/CMESurvey'))
      },
      {
        exact: true,
        guard: CMEGuard,
        expiry: ExpiryGuard,
        path: '/learning/cme-survey-opioid',
        component: lazy(() => import('src/views/new_learning/OpiodSurvayForm'))
      },
      {
        exact: true,
        guard: SchedulingGuard,
        expiry: ExpiryGuard,
        path: '/schedule/swap-request',
        component:  lazy(() =>import('src/views/Scheduling/Screens/NewShiftRequest'))
      },
      {
        exact: true,
        guard: SchedulingGuard,
        expiry: ExpiryGuard,
        path: '/schedule/my-schedule',
        component:  lazy(() =>import('src/views/Scheduling/components/NewDndcalender'))
      },
      {
        exact: true,
        path: '/schedule/availability',
        guard: SchedulingGuard,
        expiry: ExpiryGuard,
        component:lazy(() =>import('src/views/Scheduling/Screens/NewAvailability'))
      },
      {
        exact: true,
        path: '/schedule/group-schedule',
        guard: SchedulingGuard,
        expiry: ExpiryGuard,
        component:lazy(() =>import('src/views/Scheduling/components/NewTeamSchedule'))
      },
      {
        exact: true,
        guard: PaymentGuard,
        expiry: ExpiryGuard,
        path: '/schedule/payment-request',
        component:  lazy(() =>import('src/views/Scheduling/Screens/NewPaymentRequest/pages/RequestPayment'))
      },
      {
        exact: true,
        guard: PaymentGuard,
        expiry: ExpiryGuard,
        path: '/schedule/payment-history',
        component:  lazy(() =>import('src/views/Scheduling/Screens/NewPaymentRequest/pages/RequestHistory'))
      },
      {
        exact: true,
        guard: PaymentGuard,
        expiry: ExpiryGuard,
        path: '/schedule/payment-request-details/:requestId',
        component:  lazy(() =>import('src/views/Scheduling/Screens/NewPaymentRequest/pages/RequestDurationTable'))
      },
      {
        exact: true,
        path: '/expenses/travel-expense',
        guard: SchedulingGuard,
        expiry: ExpiryGuard,
        component:lazy(() =>import('src/views/new_expenses/pages/TravelExpense'))
      },
      {
        exact: true,
        path: '/expenses/meal-expense',
        guard: SchedulingGuard,
        expiry: ExpiryGuard,
        component:lazy(() =>import('src/views/new_expenses/pages/MealExpense'))
      },
      {
        exact: true,
        path: '/expenses/site-expense',
        guard: SchedulingGuard,
        expiry: ExpiryGuard,
        component:lazy(() =>import('src/views/new_expenses/pages/SiteExpense'))
      },
      {
        exact: true,
        path: '/career/contact',
        guard: CountryGuard,
        expiry: ExpiryGuard,
        component:lazy(() =>import('src/views/new_staffing/pages/Contact'))
      },
      {
        exact: true,
        path: '/career/locums',
        guard: CountryGuard,
        expiry: ExpiryGuard,
        component:lazy(() =>import('src/views/new_staffing/pages/Locums'))
      },
      {
        exact: true,
        path: '/career/job-board',
        guard: CountryGuard,
        expiry: ExpiryGuard,
        component:lazy(() =>import('src/views/new_staffing/pages/JobBoard'))
      },
      {
        exact: true,
        path: '/medtigo-orange',
        guard: MedtigoOrangeGuard,
        expiry: ExpiryGuard,
        component:lazy(() =>import('src/views/medtigoOrange'))
      },
      {
        exact: true,
        path: '/unsubscribeMail',
        component: lazy(() => import('src/components/UnsubscribeMail'))
      },
      {
        exact: true,
        path: '/imageViewer',
        component: lazy(() => import('src/components/ImageViewer'))
      },
      {
        exact: true,
        path: '/simulationRedirection/:Case/:Source',
        component: lazy(() => import('src/views/simulation/simulationRedirection'))
      },
      {
        exact: true,
        path: '/noSimulationCaseAccess/:productId',
        component: lazy(() => import('src/views/simulation/simulationNoAccessView'))
      },
      {
        exact: true,
        path: '/thankyou/fullCertificateRequest/:courseName/:email',
        component: lazy(() => import('src/views/learning/ThankyouPage'))
      },
      {
        exact: true,
        path: '/syllabusReader/:syllabusName',
        component: lazy(() => import('src/views/learning/SyllabusReader'))
      },
      {
        exact: true,
        path: '/simulationCaseSatusView/',
        component: lazy(() => import('src/views/management/SimulationCaseStatusView'))
      },
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/home" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
];
const recruiterUserRoutesConfig = [
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    path: '/',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/createJobs/',
        component: lazy(() => import('src/views/recruiter/agenciesJobCreate'))
      },
      {
        exact: true,
        path: '/viewJob/',
        component: lazy(() => import('src/views/recruiter/agenciesJobStatusView'))
      },
      {
        exact: true,
        path: '/home/',
        component: lazy(() => import('src/views/recruiter/recruiterHome'))
      },
      {
        exact: true,
        path: '/createClient/',
        component: lazy(() => import('src/views/recruiter/createClient'))
      },
      {
        exact: true,
        path: '/settings',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        component: () => <Redirect to="/home/" />
      }
    ]
  }
];

//Routes to show without use logged in
const openRoutes=[
  {
    exact: true,
    path: '/imageViewer',
    component:ImageViewer
  },{
    exact: true,
    path: '/feedback',
    component:FeedbackView
  },  {
    exact: true,
    path: '/taskResponse',
    component:TaskResponseView
  },
  {
    exact: false,
    path: '/onBoardingResendEmail',
    component: lazy(() => import('src/views/onboardingLicenseEmail'))
  },
]

const renderRoutes = routes =>
  routes ? (
    <Suspense fallback={<NewLoadingScreen />}>
      <Switch>
        {/* <Route path="/regenerate" component={RegenerateView} /> */}
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Expiry = route.expiry || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Expiry>
                  <Guard>
                    <Layout>
                      {route.routes ? (
                        renderRoutes(route.routes)
                      ) : (
                        <Component {...props} />
                      )}
                    </Layout>
                  </Guard>
                </Expiry>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  const { user } = useSelector(state => state.account);
  if(user && user.role !='recruiter'){
  return renderRoutes(routesConfig);
  } else if (localStorage.getItem('open_route')) {
    localStorage.removeItem('open_route')
    return renderRoutes(openRoutes);
  } else {
    return renderRoutes(recruiterUserRoutesConfig);
  }
}

export default Routes;
