import React, { useEffect } from 'react';
import NProgress from 'nprogress';
import { Box } from '@mui/material';
import { GradientCircularProgress } from 'src/ui/Progress';

function NewLoadingScreen() {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Box sx={{ height: '80vh', textAlign: 'center', p: 3 }}>
      <GradientCircularProgress />
    </Box>
  );
}

export default NewLoadingScreen;
