/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import * as React from 'react';
import {
  Slide
} from '@material-ui/core';
import {isMobile, isIOS} from 'react-device-detect';
const RedirectToMobile = (props) => {

  const [isVisible, setIsVisible] = React.useState(false);
  React.useEffect(() => {
    !localStorage.getItem('hide_promotion') && window.setTimeout(() => {
      setIsVisible(true);
    }, 3000);
  }, []);

  return (
    <Slide in={isMobile && isIOS && isVisible} direction="up">
      <div css={{
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        backgroundColor: '#2b71c7',
        width: '100%',
        position: 'fixed',
        bottom: 0,
        zIndex: '9999999999999',
        minHeight: 150,
        padding: '25px 20px',
        color: '#FFF',
        textAlign: 'center',
      }}>
        <div css={{
          fontWeight: 'bold',
          fontSize: 20,
        }}>It’s even better on the app</div>
        <div css={{
          marginTop: 20,
          marginBottom: 20,
        }}>
          Learn, Save and Store your medical Licenses, CMEs & Certifications with ease. With the medtigo app, the experience is seamless
        </div>
        <div css={{
          button: {
            cursor: 'pointer',
            marginBottom: 10,
            width: '100%',
            height: 50,
            fontSize: 16,
            borderRadius: 7,
            color: '#f5f5f5',
          }
        }}>
          <button onClick={() => {
            localStorage.setItem('hide_promotion', 'true');
            setIsVisible(false);
          }} css={{
            background: 'transparent',
            border: '1px solid #5f94d4',
          }}>
            Not Now
          </button>
          <button onClick={() => {
            localStorage.setItem('hide_promotion', 'true');
            setIsVisible(false);
            window.location.href = 'https://apps.apple.com/us/app/medtigo/id1519512468';
          }} css={{
            background: 'rgb(5, 0, 46)',
            border: '1px solid transparent'
          }}>
            Switch To The App
          </button>
        </div>
      </div>
    </Slide>
  );
};

export default RedirectToMobile;
