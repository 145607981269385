import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useBuyPlan } from 'src/hooks/useBuyPlan';

const data = {
  BLS: {
    plan: 19981,
    image: '/static/images/Bls.png',
  },
  ACLS: {
    plan: 19986,
    image: '/static/images/Acls.png',
  },
  PALS: {
    plan: 19984,
    image: '/static/images/Pals.png',
  },
};

const images = Object.keys(data);

const CarouselContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '700px',
  margin: 'auto',
  paddingTop: '0.5rem',
  [theme.breakpoints.down('sm')]: {
    width: '90vw'
  },
}));

const CarouselImage = styled('img')(({ theme }) => ({
  width: '100%',
  objectFit: 'cover',
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    height: 'auto',
  },
}));

const DotContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  position: 'absolute',
  bottom: '12px',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    bottom: '10px',
  },
}));

const Dot = styled('div')(({ theme, active }) => ({
  height: '8px',
  width: '8px',
  margin: '0 5px',
  borderRadius: '50%',
  backgroundColor: active ? '#2872C1' : '#E5E5E5',
  transition: 'background-color 0.3s ease',
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    height: '4px',
    width: '4px',
    margin: '0 3px',
  },
}));

const Carousel = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { buyPlan } = useBuyPlan();
  const [preloadedImages, setPreloadedImages] = useState({});

  useEffect(() => {
    // Preload images on component mount
    const loadImages = {};
    images.forEach((key) => {
      const img = new Image();
      img.src = data[key].image;
      loadImages[key] = img;
    });
    setPreloadedImages(loadImages);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % images.length);
  };

  const handleImageClick = () => {
    const currentItem = images[activeStep];
    const { plan } = data[currentItem];
    buyPlan(plan);
  };

  return (
    <CarouselContainer>
      <Box onClick={handleImageClick}>
        <CarouselImage
          src={preloadedImages[images[activeStep]]?.src}
          alt={images[activeStep]}
        />
      </Box>
      <DotContainer>
        {images.map((_, index) => (
          <Dot key={index} active={index === activeStep} onClick={() => setActiveStep(index)} />
        ))}
      </DotContainer>
    </CarouselContainer>
  );
};

export default Carousel;
