import React from 'react';

const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

function GoogleAnalytics() {
  return (
    <React.Fragment>
    </React.Fragment>
  );
}

export default GoogleAnalytics;
