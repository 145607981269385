/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import * as React from 'react';
import './welcome-styles.css';
import Slider from "react-slick";
import {useHistory} from "react-router-dom";

const options = {
  speed: 500,
  dots: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <img src="/static/icons/chevronRight.png" alt="Arrow Right"/>,
  prevArrow: <img src="/static/icons/chevronRight.png" alt="Arrow Left"/>,
  infinite: false,
  touchThreshold: 100,
  autoPlay: true,
  autoPlaySpeed: 3000,
};

const WelcomeScreen = (props) => {
  const {
    setIsTourVisible
  } = props;

  const history = useHistory();

  return (
    <div className="welcome-root">
      <header css={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'space-between',
        padding: '25px 40px',
      }}>
        <div>
          <img
            height="42px"
            src="/static/images/logo.png"
            alt="medtigoConnect Logo"
          />
        </div>
        <div css={{
          textAlign: 'right',
          fontSize: 16,
          flexGrow: 1,
        }}>
          <span onClick={() => {
            history.replace({
              pathname: '/home',
              state: {}
            });
            setIsTourVisible(false);
          }} css={{
            cursor: 'pointer',
            color: 'rgb(136, 179, 232)',
            marginTop: 7,
            '&:hover': {
              color: 'rgba(136, 179, 232, 0.81)',
            }
          }}>Go to App</span>
        </div>
      </header>
      <h1 className="main-heading" css={{
        fontWeight: 100,
        textAlign: 'center',
        fontSize: '2.3em',
        marginTop: 15,
        marginBottom: 30
      }}>Welcome To The Ultimate Provider Experience</h1>

      <Slider {...options}>
        {/*
          Starting Slide 1
        */}
        <div className='slide1-wrapper'>
          <div className="slider-item-full slide-1" id="slide-1" key="slide-1">
            <div 
            style={{position:'relative'}}
            >
              <img src="/static/images/welcome/welcome-1.png" alt="Mobiles Image" css={{
                height: '35vh'
              }} />
              <img src="/static/images/welcome/background-wave.png" className='background-wave' alt="Mobiles Image" />
            </div>
            <div>
              <h3 css={{
                fontWeight: 400,
                marginTop: 10,
              }}>Never Pay for ACLS, PALS, BLS Again.</h3>
              <div className="full-width" css={
                css`
              font-size: 16px;
              width: 27%;
              margin: 0 auto;
              margin-top: 15px;
              font-weight: 200;
           `}>
                Recertify on the same day, 100% online on the medtigo app. And the best part? It’s free.
            </div>
            </div>
          </div>
        </div>
        {/*
          Ending Slide 1
        */}
         {/*
          Starting Slide 2
        */}
        <div className="slider-item-full slide-half" id="slide-3" key="slide-3">
          <div className="slider-half-item" css={css`
            padding-right: 6%;
            align-items: flex-end !important;
          `}>
            <img className="vertical-image" src="/static/images/welcome/welcome-2-new.png" alt="Mobiles Image" css={{
              height: '42vh',
              margin: '0 !important',
            }}/>
         </div>
          <div className="slider-half-item text" css={{
            alignItems: 'flex-start !important',
            paddingRight: '15%',
            textAlign: 'left',
          }}>
            <span css={{color: 'rgb(43, 133, 229)', fontSize: 14}}>CME/CE Credits</span>
            <h4 css={{
              fontWeight: 400,
              fontSize: 20,
            }}>Earn CME/CE credits when you complete your ACLS, PALS & BLS.

          </h4>
            <div css={css`
              font-size: 17px;
              margin-top: 25px;
              font-weight: 300;
            `}>
              Meet your continuing medical education requirements by obtaining 4.25, 4.00 and 3.00 CME/CE credits upon completing the ACLS, PALS and BLS courses respectively.
            </div>
          </div>
        </div>
        {/*
          Ending Slide 2
        */}
        {/*
          Starting Slide 3
        */}
        <div className="slider-item-full slide-half" id="slide-2" key="slide-2">
          <div className="slider-half-item text" css={css`
            padding-left: 15%;
            text-align: left;
            align-items: flex-start !important;
          `}>
            <span css={{color: 'rgb(43, 133, 229)', fontSize: 14}}>Free Certificate Email Reminders</span>
            <h4 css={{
              fontWeight: 400,
              fontSize: 20,
            }}>Never Miss an Expiry Date Again.</h4>
            <div className="aligncenter" css={css`
              font-size: 17px;
              margin-top: 25px;
              font-weight: 300;
            `}>
               Stay informed about your certificate and license expirations. Free Automated Email Reminders at 120, 90 and 60 days for ACLS, BLS, PALS and Licenses.
            </div>
          </div>
          <div className="slider-half-item">
            <img src="/static/images/welcome/welcome-2.png" alt="Mobiles Image" css={{
              height: '35vh'
            }}/>
          </div>
        </div>
        {/*
          Ending Slide 3
        */}
        {/*
          Starting Slide 4
        */}
        <div className="slider-item-full slide-half" id="slide-3" key="slide-3">
          <div className="slider-half-item" css={css`
            padding-right: 6%;
            align-items: flex-end !important;
          `}>
            <img className="vertical-image" src="/static/images/welcome/welcome-3.png" alt="Mobiles Image" css={{
              height: '42vh',
              margin: '0 !important',
            }}/>
          </div>
          <div className="slider-half-item text" css={{
            alignItems: 'flex-start !important',
            paddingRight: '15%',
            textAlign: 'left',
          }}>
            <span css={{color: 'rgb(43, 133, 229)', fontSize: 14}}>Certificate & CME Tracker</span>
            <h4 css={{
              fontWeight: 400,
              fontSize: 20,
            }}>All Your Certificates in One Place.</h4>
            <div css={css`
              font-size: 17px;
              margin-top: 25px;
              font-weight: 300;
            `}>
              When you have your licenses, certificates and CMEs in one place, it's easier to track your career growth. You can easily share these with hospitals as well, using your medtigo app.
            </div>
          </div>
        </div>
        {/*
          Ending Slide 4
        */}
         {/*
          Starting Slide 5
        */}
        <div className="slider-item-full slide-half" id="slide-2" key="slide-2">
          <div className="slider-half-item text" css={css`
            padding-left: 15%;
            text-align: left;
            align-items: flex-start !important;
          `}>
            <span css={{ color: 'rgb(43, 133, 229)', fontSize: 14 }}>Staffing Service & Job Board</span>
            <h4 css={{
              fontWeight: 400,
              fontSize: 20,
            }}>Accelerate Your Career & Get Paid More
            </h4>
            <div className="aligncenter" css={css`
              font-size: 17px;
              margin-top: 25px;
              font-weight: 300;
            `}>
              Explore rewarding work opportunities with medtigo professionals working as your partners and personal agents. Browse, search and easily apply for meaningful jobs.
            </div>
          </div>
          <div className="slider-half-item">
            <img src="/static/images/welcome/welcome-5-new.png" alt="Mobiles Image" css={{
              height: '35vh'
            }}/>
          </div>
        </div>
        {/*
          Ending Slide 5
        */}
         {/*
          Starting Slide 6
        */}
        <div className="slider-item-full slide-half" id="slide-3" key="slide-3">
          <div className="slider-half-item" css={css`
            padding-right: 6%;
            align-items: flex-end !important;
          `}>
            <img className="vertical-image" src="/static/images/welcome/welcome-6-new.png" alt="Mobiles Image" css={{
              height: '42vh',
              margin: '0 !important',
            }}/>
          </div>
          <div className="slider-half-item text" css={{
            alignItems: 'flex-start !important',
            paddingRight: '15%',
            textAlign: 'left',
          }}>
            <span css={{color: 'rgb(43, 133, 229)', fontSize: 14}}>Premium Healthcare Services</span>
            <h4 css={{
              fontWeight: 400,
              fontSize: 20,
            }}>Get Instant Access To Professional Services.
          </h4>
            <div css={css`
              font-size: 17px;
              margin-top: 25px;
              font-weight: 300;
            `}>
               A curated set of services and resources for Healthcare Professionals including study guides, CV reformatting and lifestyle benefits are accessible to you as a medtigo member.
            </div>
          </div>
        </div>
        {/*
          Ending Slide 6
        */}
        {/*
          Starting Slide 7
        */}
        <div className="slider-item-full slide-half" id="slide-4" key="slide-4">
          <div className="slider-half-item" css={css`
            padding-left: 15%;
            text-align: left;
            align-items: flex-start !important;
          `}>
            <span css={{color: 'rgb(43, 133, 229)', fontSize: 14}}>State Licensing Service</span>
            <h4 css={{
              fontWeight: 400,
              fontSize: 20,
            }}>Say Goodbye To Worrying About Your Medical License Application.</h4>
            <div css={css`
              font-size: 17px;
              margin-top: 25px;
              font-weight: 300;
            `}>
              medtigo members can leverage our teams 40+ years of experience with the necessary process when applying to license with a medical board.
            </div>
          </div>
          <div className="slider-half-item">
            <img src="/static/images/welcome/welcome-7-new.png" alt="Mobiles Image" className="no-margin" css={{
              height: '35vh',
            }}/>
          </div>
        </div>
        {/*
          Ending Slide 7
        */}
         {/*
          Starting Slide 8
        */}
        {/* <div className="slider-item-full slide-half" id="slide-4" key="slide-4">
          <div className="slider-half-item" css={css`
            padding-left: 15%;
            text-align: left;
            align-items: flex-start !important;
          `}>
            <span css={{color: 'rgb(43, 133, 229)', fontSize: 14}}>Free Access to medtigo Bot</span>
            <h4 css={{
              fontWeight: 400,
              fontSize: 20,
            }}>An Intelligent Personal Assistant</h4>
            <div css={css`
              font-size: 17px;
              margin-top: 25px;
              font-weight: 300;
            `}>
              Ask us anything. Tell us anything. Assistance, suggestions and feedback, we are all ears.
            </div>
          </div>
          <div className="slider-half-item">
            <img src="/static/images/welcome/welcome-4.png" alt="Mobiles Image" className="no-margin" css={{
              height: '60vh',
              marginTop: '150px !important',
            }}/>
          </div>
        </div> */}
        {/*
          Ending Slide 8
        */}
        {/*
          Starting Slide 9
        */}
        <div className="slider-item-full slide-1" id="slide-5" key="slide-5">
          <div>
            <img src="/static/images/welcome/welcome-5.png" alt="Mobiles Image" css={{
              height: '35vh',
              marginTop: '25px !important',
            }}/>
          </div>
          <div>
            <h3 css={{
              fontWeight: 400,
              marginTop: 35,
            }}>When You’re a medtigo Member,<br/>You’re Covered.</h3>
          </div>
        </div>
        {/*
          Ending Slide 9
        */}
      </Slider>
      <button className="start-course-btn" onClick={() => {
        history.replace({
          pathname: '/learning',
          state: {}
        });
      }}>
        Start Course
      </button>
    </div>
  );
}

export default WelcomeScreen;
