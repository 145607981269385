/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import {colors, createMuiTheme, responsiveFontSizes} from '@material-ui/core';
import typography from './typography';
import {softShadows, strongShadows} from './shadows';
import {THEMES} from '../constants';

const buttonsHover = {
  root: {
    disabled: {
      backgroundColor: 'inherit !important'
    },
    transitionProperty: 'filter, background-color, box-shadow, border',
    '&:hover': {
      backgroundColor: 'rgba(35, 44, 81, 0.7)',
      filter: 'brightness(115%)'
    }
  }
};

const baseConfig = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiGrid: {
      root: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      }
    },
    MuiInputBase: {
      root: {
        borderBottom: '1px solid rgb(67, 108, 159)',
        color: '#000 !important',
      },
    },
    MuiInputLabel: {
      root: {
        color: '#000',
        fontSize: 14,
      },
      '&$focused': {
        color: '#000',
      },
    },
    MuiInput: {
      underline: {
        color: 'rgb(44, 54, 93)',
        '&:hover': {
          color: 'rgb(44, 54, 93)',
        }
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiIconButton: buttonsHover,
    MuiButton: buttonsHover,
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    }
  }
};

const themeConfigs = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      }
    },
    palette: {
      type: 'light',
      action: {
        active: colors.blueGrey[600]
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white
      },
      primary: {
        main: colors.indigo[600]
      },
      secondary: {
        main: '#5850EC'
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600]
      }
    },
    shadows: softShadows
  },
  {
    name: THEMES.ONE_DARK,
    palette: {
      type: 'dark',
      certificatesGrid: 'rgba(61,134,224,0.07)',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: 'rgba(44, 54, 94, 1)',
        dark: '#1a193f',
        light: 'rgba(67, 108, 160, 0.7)',
        paper: 'rgba(44, 54, 94, 1)',
        grey: '#f4f6f8',
        linear: 'linear-gradient(180deg, rgba(0,11,26,1) 0%, rgba(67,108,159,1) 60%, rgba(5,0,46,1) 100%)',
        white: '#FFF'
      },
      primary: {
        main: 'rgb(67, 108, 159)'
      },
      secondary: {
        main: '#fff',
      },
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb',
        dark: '#000',
      }
    },
    shadows: strongShadows
  },
  {
    name: THEMES.UNICORN,
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#2a2d3d',
        dark: '#222431',
        paper: '#2a2d3d'
      },
      primary: {
        main: '#a67dff'
      },
      secondary: {
        main: '#a67dff'
      },
      text: {
        primary: '#f6f5f8',
        secondary: '#9699a4'
      }
    },
    shadows: strongShadows
  }
];

export function createTheme(settings = {}) {
  let themeConfig = themeConfigs.find((theme) => theme.name === settings.theme);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${settings.theme} is not valid`));
    [themeConfig] = themeConfigs;
  }

  let theme = createMuiTheme(
    _.merge(
      {},
      baseConfig,
      themeConfig,
      {direction: settings.direction}
    )
  );

  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}
