import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import authService from 'src/services/authService';
import MultiTabBlockPage from './MultiTabBlockPage';
import { AUTH_QA, AUTH_URL } from 'src/settings';

function AuthGuard({ children }) {
  const account = useSelector((state) => state.account);
  const urlParams = new URLSearchParams(window.location.search);
  const admin_token = urlParams.get('admin_token') || urlParams.get('t');
  const destination = window.location.pathname;
  const url= window.location.origin;
  if (!account.user) {
    if (url == "http://qa.medtigo.com") {
      window.location.href = AUTH_QA+ "?path=redirect&route=" + destination;
    } else {
      if (localStorage.getItem('reset')) {
        window.location.href = AUTH_URL + "login/?/settings";
      } else {
        window.location.href = AUTH_URL + "?path=redirect&route=" + destination;
      }
    }
    return <h1>Please Wait</h1>
  }
  // Temporary commented code to access simualtion cases smoothly from courses
  // if (authService.handleRestrictToOneTab()) {
  //   document.querySelector('.zsiq_floatmain') && document.querySelector('.zsiq_floatmain').classList.remove('visible');
  //   return <MultiTabBlockPage/>
  // }

  if (destination === '/market-auto-login'){
    localStorage.removeItem('data')
    window.location.href =' https://medtigo.store/cart' + '?t=' + account.user.wp_token;
  }

  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.any
};

export default AuthGuard;
