// const SERVER_URL = 'http://localhost:8080/';              // SERVER URL FOR LOCAL DEVELOPMENT
// const SERVER_URL = 'https://qa.medtigo.com/';             // SERVER URL FOR QA TESTING
const SERVER_URL = 'https://connect.medtigo.com/';        // SERVER URL FOR LIVE
// const MARKET_URL = "https://staging5.market.medtigo.com/" // MARKET URL FOR QA TESTING
const MARKET_URL = 'https://medtigo.store/';         // MARKET URL FOR LIVE
const AUTH_URL = 'https://auth.medtigo.com/';
const AUTH_QA="https://auth-qa.medtigo.com/"
// const AUTH_URL = 'https://connect.medtigo.com/';

const APP_URL = SERVER_URL + 'api/v1';
// const CONNECT_URL = 'http://localhost:3001';             // APP URL FOR LOCAL DEVELOPMENT
// const CONNECT_URL = 'https://qa.medtigo.com';            // APP URL FOR QA TESTING
const CONNECT_URL = 'https://connect.medtigo.com';       // APP URL FOR LIVE

export {
  SERVER_URL,
  APP_URL,
  MARKET_URL,
  AUTH_URL,
  CONNECT_URL,
  AUTH_QA
}