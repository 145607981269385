import { useSnackbar } from 'notistack';

export const useOpenSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar();

  const openSnackbar = (text, variant = 'success', duration = 3000) => {
    enqueueSnackbar(text, {
      variant: variant,
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'top',
      },
      autoHideDuration: duration,
    });
  };

  return openSnackbar;
};
