/** @jsx jsx */
/** @jsxFrag React.Fragment **/
import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Card, Dialog, TextField, DialogTitle, DialogContent, DialogActions, TextareaAutosize, FormControlLabel, RadioGroup, Radio, makeStyles, Grid, withStyles, FormControl, Box } from '@material-ui/core';
import certificatesTaskService from 'src/services/certificateTaskService';
import orderServices from 'src/services/orderServices';
import { Rating } from '@mui/material';
import { jsx, css } from '@emotion/core';
import Typography from '@mui/material/Typography';
import './style.css';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import alertPage from 'src/services/alertPage';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
    textFields: {
        marginBottom: 20,
        width: '100%'
    }
}));

const StyledRating = withStyles({
    root: {
        borderRadius: "100px",
        // backgroundColor: "#D9D9D9",
        color: "#717171",
        // display: "flex",
        // gap: "10px"

    },
    iconFilled: {
        color: '#ffffff',
        backgroundColor: '#2B7DF8',
        borderRadius: "100px",

    },
    // iconHover: {
    //   color: '#ff3d47',
    // },
})(Rating);

const customIcons = {
    1: {
        icon: <div style={{ padding: "10px 20px" }}>1</div>,
        label: 'Very Dissatisfied',
    },
    2: {
        icon: <div style={{ padding: "10px 20px" }}>2</div>,
        label: 'Dissatisfied',
    },
    3: {
        icon: <div style={{ padding: "10px 20px" }}>3</div>,
        label: 'Neutral',
    },
    4: {
        icon: <div style={{ padding: "10px 20px" }}>4</div>,
        label: 'Satisfied',
    },
    5: {
        icon: <div style={{ padding: "10px 20px" }}>5</div>,
        label: 'Very Satisfied',
    },
};

function IconContainer(props) {
    const { value, ...other } = props;
    return <span style={{color:'white',height:'50px',width:'50px', alignItems:'center', border:'0.5px solid white', borderRadius:'50%' , flex: "1", margin:"0 10px" }} {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
    value: PropTypes.number.isRequired,
};
function FeedbackView() {
    const classes = useStyles();
    const [UserId, setUserId] = useState("")
    const [comment, setComment] = useState("")
    const [file, setFile] = useState(null)
    const [first_name, setFirstName] = useState("")
    const [last_name, setLastName] = useState("")
    const [cursor, setCursor] = useState('pointer')
    const [isRequestSend, setIsRequestSend] = useState(false)

    const { user } = useSelector((state) => state.account);
    const [feedbackPopUpOpen, setFeedBackPopUpOpen] = useState(false)
    const [contact, setContact] = useState('yes')
    const [feebackText, setFeedbackText] = useState('')
    const [ratingValue, setRatingValue] = useState(1)
    const { enqueueSnackbar } = useSnackbar();


    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)


    useEffect(() => {
        if (queryParams.get("id")) setUserId(queryParams.get("id"))
        if (queryParams.get("first_name")) setFirstName(queryParams.get("first_name"))
        if (queryParams.get("last_name")) setLastName(queryParams.get("last_name"))
    })

    const handleSubmitLicenseFeedback = async () => {
        try {
            let payload = {
                User_Name: first_name + " " + last_name,
                Rating: ratingValue,
                Feedback: feebackText,
                UserId: UserId,
                contact
            }
            const data = await alertPage.submitFeedbackFromEmail(payload)
            setFeedBackPopUpOpen(false)
            enqueueSnackbar('Feeback Submitted.', {
                variant: 'success'
            });
        }
        catch (error) {
            console.log(error);
            enqueueSnackbar('Something went wrong.', {
                variant: 'error'
            });
        }
    }



    return <div
        css={{
            background: 'linear-gradient(#04002C 30%, #223363, #41699C)',
            height: '100vh',
            display: 'flex',
            flexDirection: "column",
            // width: 'device-width',
            fontFamily: 'sans-serif',
        }}>
        <div css={{
            backgroundColor: '#2C365E',
            height: '60px',
        }}>
            <img src='static/images/logo.png' css={{
                height: 'inherit',
                width: 'inherit',
                paddingLeft: '20px',
                paddingBottom: '2px',
                paddingTop: '2px'
            }}></img>
        </div>
        <div css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 'auto auto',
            padding: '20px 90px',
            background: 'linear-gradient(#131948, #41699C)',
            fontSize: '18px',
            color: '#fff',
            textAlign: "left",
            gap: '20px',
            borderRadius: '3px',
            letterSpacing: '0.5px',
            boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
            height: 'fit-content'
        }}>
            <div
                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
            >

                <img
                    src="/static/icons/feedback_icon.png"
                    style={{ height: '40px', width: '40px' }}
                />
                <label css={{
                    fontSize: 16
                }}>
                    Feedback
                </label>

            </div>
            <Box>

                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: 'left' }}>

                    <Typography >Your overall satisfaction of our service</Typography>
                    <Grid container spacing={4} css={css`{width:100%; margin:auto; display:flex; justify-content:center;margin-top:0px}`}>

                        <Grid item xs={8}>
                            <StyledRating
                                style={{fontSize: '16px', display: "flex", justifyContent: "space-between" }}
                                name="highlight-selected-only"
                                value={ratingValue}
                                onChange={(_, newValue) => {
                                    setRatingValue(newValue)
                                }}
                                IconContainerComponent={IconContainer}
                                getLabelText={(value) => customIcons[value].label}
                            />
                        </Grid>
                        <Grid item xs={2}></Grid>
                    </Grid>

                </div>
                                <div css={css`{font-size:12px;width:100%; margin:auto; display:flex; justify-content:space-between}`}>
                                <label>Not Satisfied</label>
                                <label>Really Satisfied</label>
                                </div>




                <div style={{ margin: '20px 0', textAlign: 'left' }}>

                    <Typography style={{ margin: '10px 0' }}>What is the main reason for score?</Typography>
                    <TextareaAutosize
                        cols={3}
                        rowsMin={5}
                        style={{ height:'150px', width: '100%', backgroundColor: 'transparent', color: 'white', padding: '10px', fontSize: '14px', outline: 'none', marginTop: '10px', borderRadius: '5px', border: '1.4px solid #8f8f8f',  overflow:'auto', resize:'none'  }}
                        value={feebackText}
                        onChange={(e) => setFeedbackText(e.target.value)}
                    />
                </div>
                <FormControl>
                    <Typography id="demo-radio-buttons-group-label" >Would you like a representative to contact you?</Typography>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={contact}
                        onChange={(e) => {
                            setContact(e.target.value)
                        }}
                        style={{ display: "flex" }}
                    >
                        <Box
                            style={{ display: 'flex' }}
                        >
                            <FormControlLabel value="yes" control={<Radio color='secondary' />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio color='secondary' />} label="No" />
                        </Box>
                    </RadioGroup>
                    <div
          css={css`{width:100%; margin:auto; display:flex; justify-content:center}`}>
                    <Button
                        type='button'
                        onClick={handleSubmitLicenseFeedback}
                        disabled={isRequestSend}
                        css={{
                            backgroundColor: '#0B66BF',
                            color: "#fff",
                            fontSize: '14px',
                            height: '30px',
                            border: 'none',
                            borderRadius: '5px',
                            padding: '5px 30px',
                            fontWeight: 'bold',
                            letterSpacing: '1px',
                            fontFamily: 'sans-serif',
                            width:'fit-content',
                            boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
                        }}>
                        Submit
                    </Button>
                    </div>
                </FormControl>
            </Box>



        </div>
    </div>
}

export default FeedbackView